<template>
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <div class="navbar-end">
      <ul>
        <li>
          <router-link class="" to="/quienes">Quienes Somos</router-link>
        </li>
        <li class="is-active"><a href="#" aria-current="page">CV</a></li>
      </ul>
      </div>
    </nav>
</template>
<script>
export default {
  name: 'Breadcrumb'
}
</script>

<style scoped>
.breadcrumb {
    padding: 25px 25px;
    background: url(../assets/breadcrumb.png);
    overflow: hidden;
}
</style>