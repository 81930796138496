<template>
  <section class="cv content">
    <Breadcrumb />
    <h3>Curriculum Vitae</h3>
    <div class="columns">
      <div class="column"></div>
      <div class="column">
        <img
          alt=""
          src="https://farm8.staticflickr.com/7417/12121384335_f70894ae08_n.jpg"
        />
      </div>
      <div class="column">
        <div class="content">
          <p class="has-text-justified">
          <b>Daniel E. Leguiza</b> Lic. En Kinesiología y Fisioterapia (MP 5781)
          egresado de la Universidad Nacional de Córdoba, se ha formado
          constantemente en Terapias Manuales Innovadoras, indicadas para la
          prevención, tratamiento y supresión del dolor del sistema
          osteomioarticular.
        </p>
        <div class="field is-grouped is-grouped-multiline">
          <div class="control">
            <div class="tags has-addons">
              <span class="tag">
                <font-awesome-icon class="mr-2" :icon="['fa', 'check']" />
              </span>
              <span class="tag is-info">Especialista RPG</span>
            </div>
          </div>
          <div class="control">
            <div class="tags has-addons">
              <span class="tag">
                <font-awesome-icon class="mr-2" :icon="['fa', 'check']" />
              </span>
              <span class="tag is-info">Osteopata</span>
            </div>
          </div>
          <div class="control">
            <div class="tags has-addons">
              <span class="tag">
                <font-awesome-icon class="mr-2" :icon="['fa', 'check']" />
              </span>
              <span class="tag is-info">Terapista Manual concepto Mulligan</span>
            </div>
          </div>
          <div class="control">
            <div class="tags has-addons">
              <span class="tag">
                <font-awesome-icon class="mr-2" :icon="['fa', 'check']" />
              </span>
              <span class="tag is-info">Prev y tto de lesiones deportivas</span>
            </div>
          </div>
          <div class="control">
            <div class="tags has-addons">
              <span class="tag">
                <font-awesome-icon class="mr-2" :icon="['fa', 'check']" />
              </span>
              <span class="tag is-info">Miembro de la A.A RPG </span>
            </div>
          </div>
          <div class="control">
            <div class="tags has-addons">
              <span class="tag">
                <font-awesome-icon class="mr-2" :icon="['fa', 'check']" />
              </span>
              <span class="tag is-info">Miembro de RPG Latinoamerica </span>
            </div>
          </div>
          <div class="control">
            <div class="tags has-addons">
              <span class="tag">
                <font-awesome-icon class="mr-2" :icon="['fa', 'check']" />
              </span>
              <span class="tag is-info">Integrante academico de Columna spine</span>
            </div>
          </div>
        </div>
        </div>
        
      </div>
      <div class="column"></div>
    </div>

    <div class="columns" v-for="item in items" :key="item.id">
      <div class="column is-2"></div>
      <div class="column is-1">
        <div class="year_1">{{ item.to }}</div>
        <div>-</div>
        <div class="year_2">{{ item.from }}</div>
      </div>
      <div class="column is-6">
        <h3 class="cv-title">{{ item.title }}</h3>
        <h5>{{ item.subtitle }}</h5>
        <h6>{{ item.location }}</h6>
      </div>
      <div class="column is-3"></div>
    </div>
  </section>
</template>
<script>
// @ is an alias to /src
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  name: "CV",
  components: {
    Breadcrumb,
  },
  data: function () {
    return {
      items: [
        {
          id: "1",
          from: "2018",
          to: "Actualidad",
          title: "Director – Coordinador",
          subtitle: "Centro Consultorios Profesionales",
          location: "Argentina, Cordoba",
          description:
            "Somos un grupo interdisciplinario de profesionales de la salud especializados en el en el cuidado físico y psíquico de nuestros pacientes, siendo nuestro objetivo primordial la prevención y tratamiento de patologías del sistema neuromusculoesquelético,  mejorando así la calidad de vida. Contamos con Rehabilitación en el Área de Traumatología, Reeducación Postural Global, Osteopatía, Estudio digital de la pisada y la postura, Entrenamiento aplicado a la lesión, Nutrición, Psicología. Somos un centro modelo de rehabilitación en La ciudad de La Calera, ubicado en una zona de intercountrys y de mayor crecimiento de los últimos tiempos en la ciudad.",
        },
        {
          id: "2",
          from: "2012",
          to: "Actualidad",
          title: "Director – Coordinador",
          subtitle: "RPG Centro- Reeducación Postural Global",
          location: "Argentina, Cordoba",
          description:
            "Director- Coordinador de las diferentes especialidades. RPG en consultorio de Terapia Física Especializada. Colombia 69. Planta Baja 'A'. Nueva Córdoba. Tel: 0351-153564564. Responsablie: Lic. Leguiza, Daniel",
        },
        {
          id: "3",
          from: "2014",
          to: "2018",
          title: "RPG en ITC (Instituto Traumatologico de Córdoba)",
          subtitle: "RPG Centro- Reeducación Postural Global",
          location: "Argentina, Cordoba",
          description:
            "Carlos Gauss 5639. Villa Belgrano. Córdoba Tel: 03543-445353. Director Médico: Dr. Sanchez, Pulgar",
        },
        {
          id: "4",
          from: "2014",
          to: "Actualidad",
          title: "Pro Life- Entrenamiento y Rehabilitación",
          subtitle: "RPG en consultorio de Terapia Física Especializada.",
          location: "Argentina, Cordoba",
          description: "Chacabuco 799. Nueva Cordoba.",
        },
        {
          id: "6",
          from: "2010",
          to: "2012",
          title: "Instituto Privado de Kinesiologia y Fisioterapia Balbuena.",
          subtitle: "Kinesiologia y Fisioterapia Area traumatologia.",
          location: "Argentina, Cordoba",
          description: "",
        },
      ],
    };
  },
};
</script>

<style scoped>
h3.cv-title {
  /*
    color: #000000; 
    font-size: 22px; 
    line-height: 22px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    */

  padding-bottom: 18px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 18px;
  position: relative;
}
h3.cv-title:after {
  position: absolute;
  content: "";
  width: 40px;
  height: 2px;
  background: #0076f9;
  bottom: -1px;
  left: 0;
}
</style>