<template>
  <section class="section rpg">
    <div class="container">
      <div class="columns">
        <div class="column is-one-quarter">
            <figure class="image is-3by2 mt-6">
              <img
                class="img"
                alt="RPG hombros"
                src="../assets/rpg/rpg_1.jpeg"
              />
            </figure>
        </div>
        <div class="column">
          <div class="content">
            <h2><i class="fa fa-check-square-o"></i> Metodolog&iacute;a RPG</h2>
            <p>
              A través de posturas de tratamiento, graduales y progresivas
              aplicadas a las necesidades terapéuticas de cada persona se busca
              un estiramiento global de fascias, músculos y tejidos. Permitiendo
              la aplicación de posturas tanto en periodos agudos como crónicos
              de cada patología con la finalidad de Corregir la Postura y/o
              Suprimir la sintomatología.
            </p>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-one-quarter">
          <figure class="image is-3by2 mt-6">
              <img
                class="img"
                alt="RPG hombros"
                src="../assets/rpg/rpg_2.jpeg"
              />
            </figure>
        </div>
        <div class="column">
          <div class="content">
            <h2>
              <i class="fa fa-check-square-o"></i> Patologías y Beneficios
            </h2>
            <p class="wb">
              A veces el origen del dolor es la misma zona que genera la
              consulta de cada paciente, pero otras veces el problema se ubica
              en una zona lejana al motivo de consulta. Por ello no podemos
              tratar la Columna Cervical sin tratar toda la columna en su
              totalidad. Por ello en RPG trabajamos la Globalidad del paciente.
            </p>
            <div class="field is-grouped is-grouped-multiline">
              <div class="control">
                <div class="tags has-addons">
                  <span class="tag">
                    <font-awesome-icon class="mr-2" :icon="['fa', 'check']" />
                  </span>
                  <span class="tag is-info">Escoliosis</span>
                </div>
              </div>
              <div class="control">
                <div class="tags has-addons">
                  <span class="tag">
                    <font-awesome-icon class="mr-2" :icon="['fa', 'check']" />
                  </span>
                  <span class="tag is-info">Cervicalgias</span>
                </div>
              </div>
              <div class="control">
                <div class="tags has-addons">
                  <span class="tag">
                    <font-awesome-icon class="mr-2" :icon="['fa', 'check']" />
                  </span>
                  <span class="tag is-info">Dorsalgias y Lumbalgias</span>
                </div>
              </div>
              <div class="control">
                <div class="tags has-addons">
                  <span class="tag">
                    <font-awesome-icon class="mr-2" :icon="['fa', 'check']" />
                  </span>
                  <span class="tag is-info"
                    >Rectificacion cervical, dorsal y lumbar</span
                  >
                </div>
              </div>
              <div class="control">
                <div class="tags has-addons">
                  <span class="tag">
                    <font-awesome-icon class="mr-2" :icon="['fa', 'check']" />
                  </span>
                  <span class="tag is-info">Cifosis Dorsal</span>
                </div>
              </div>
              <div class="control">
                <div class="tags has-addons">
                  <span class="tag">
                    <font-awesome-icon class="mr-2" :icon="['fa', 'check']" />
                  </span>
                  <span class="tag is-info">Hernias de discos</span>
                </div>
              </div>
              <div class="control">
                <div class="tags has-addons">
                  <span class="tag">
                    <font-awesome-icon class="mr-2" :icon="['fa', 'check']" />
                  </span>
                  <span class="tag is-info">Patologías del Periné</span>
                </div>
              </div>
              <div class="control">
                <div class="tags has-addons">
                  <span class="tag">
                    <font-awesome-icon class="mr-2" :icon="['fa', 'check']" />
                  </span>
                  <span class="tag is-info">Valgo-Varo de Rodilla</span>
                </div>
              </div>
              <div class="control">
                <div class="tags has-addons">
                  <span class="tag">
                    <font-awesome-icon class="mr-2" :icon="['fa', 'check']" />
                  </span>
                  <span class="tag is-info">Vertigo de origen mecánico</span>
                </div>
              </div>
            </div>
            <p>
              <b>BENEFICIOS DE RPG:</b> Gracias a RPG, el paciente puede corregir
              situaciones de conflicto, ganar flexibilidad, eliminar dolores,
              independizarse de medicamentos paliativos e incluso evitar
              instancias cruentas como una cirugía, además de recuperar la
              funcionalidad. La RPG brinda un abordaje personalizado y a medida
              de cada paciente, mientras que otros tratamientos exigen 3 o 4
              sesiones por semana, con RPG es suficiente una sesión semanal para
              observar los cambios. Algunas Patologías: Hernia de Disco.
              Escoliosis. Valgo o Varo de Rodillas. Rectificación Cervical.
              Dorso Curvo o Cifosis Dorsal.
            </p>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-one-quarter">
          <figure class="image is-3by2 mt-6">
              <img
                class="img"
                alt="RPG hombros"
                src="../assets/rpg/rpg_3.jpeg"
              />
            </figure>
        </div>
        <div class="column">
          <div class="content">
            <h2><i class="fa fa-check-square-o"></i> Principios RPG</h2>
            <p>
              <b>Individualidad:</b> Somos diferentes unos de otros. No hay una lesión
              idéntica a otra; tampoco pueden encontrarse dos formas idénticas
              de responder a una agresión, por lo que la evaluación es
              exhaustiva a modo de determinar la forma de abordaje correcta.
            </p>
            <p>
              <b>Causalidad:</b> Si sufrimos una caída y sentimos dolor, la causa de
              ese dolor es evidente; pero si un día nos levantamos de la cama
              con dolor, sin que haya ocurrido nada que lo justifique es que
              estamos frente a una causa oculta. Como se describe más abajo, el
              primer mecanismo de defensa es no sufrir. Para ocultar un dolor
              haremos compensaciones antiálgicas. Supongamos que nos torcemos un
              tobillo. El dolor producirá una contracción muscular refleja para
              proteger la articulación. Si la lesión tarda en curarse, se
              producirá un acortamiento muscular permanente que irá alternando
              sucesivamente la buena posición de los distintos segmentos óseos:
              tibia, fémur, pelvis, vértebras, etc… Un buen día podemos sufrir
              una lumbalgia a causa de esa mala posición. De a poco nos servirá
              tratar la zona lumbar si no disponemos de un método capaz de
              remontarse a la causa, siguiendo el hilo del acortamiento muscular
              producido por aquella lesión antigua. Segundo error: trabajar sólo
              el síntoma. Hay que buscar la causa.
            </p>
            <p>
              <b>Globalidad:</b> Uno de los aportes más importante de la R.P.G. es el
              descubrimiento de que los músculos estáticos se asocian en cadenas
              funcionales, unidos entre sí por un sistema de fascias y
              aponeurosis, de manera que no se puede tirar del extremo de un
              músculo sin alterar el resto de la cadena. Cuando se produce una
              lesión, ésta queda fijada en forma de acortamiento muscular, y se
              "diluye" a lo largo de las cadenas musculares que tiene más
              próximas pudiendo dar síntomas a distancia. Para poder remontarnos
              a la causa habrá que poner en tensión toda la cadena muscular
              afectada.
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <div class="main-feature-icon"></div>
        </div>
        <div class="col-md-9">
          <div class="main-feature-content">
            <h2><i class="fa fa-check-square-o"></i> Lectura de Interés</h2>
            <ul class="tag-list">
              <li>
                <a href="http://www.rpg.org.es/" target="_blank">
                  <i class="fa fa-check-circle"></i>Asociación Española de RPG
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
