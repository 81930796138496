<template>
  <section class="section quienes-somos">
    <div class="columns">
      <div class="column">
        <figure class="image is-3by2">
          <img src="../assets/quienes/quienes1.jpg" alt="RPG Centro Quienes" />
        </figure>
      </div>
      <div class="column is-four-fifths">
        <div class="content">
          <h2>Quienes Somos</h2>
          <p>
            Somos un grupo interdisciplinario de profesionales de la salud
            especializados en el en el cuidado físico y psíquico de nuestros
            pacientes, siendo nuestro objetivo primordial la prevención y
            tratamiento de patologías del sistema neuromusculoesquelético,
            mejorando así su calidad de vida. Sin dejar de remarcar que estamos
            formados por los avales internacionales de todas las terapias que
            realizamos en nuestro espacio.
          </p>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <figure class="image is-3by2">
          <img src="../assets/quienes/quienes2.jpg" alt="RPG Centro Premisas" />
        </figure>
      </div>
      <div class="column is-four-fifths">
        <div class="content">
          <h2>Premisas</h2>
          <p>
            Como equipo interdisciplinario de profesionales de la salud,
            entendemos que una persona con características biológicas y
            psicológicas individuales, necesita de un sistema preventivo y de
            tratamiento absolutamente individualizado, con el fin de abarcar los
            aspectos físicos y psíquicos, para mejorar y ayudar a su calidad de
            vida. A partir de esta premisa surge la iniciativa de crear RPG
            Centro.
          </p>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <figure class="image is-3by2">
          <img src="../assets/quienes/quienes3.jpg" alt="RPG Centro Mision" />
        </figure>
        <br/>
        <br/>
        <br/>
        <br/>
        <figure class="image is-3by2">
          <img src="../assets/quienes/quienes4.jpg" alt="RPG Centro Mision" />
        </figure>
        

      </div>
      <div class="column is-four-fifths">
        <div class="content">
          <h2>Misión, Visión, Valores Institucionales</h2>
          <h3>Misión</h3>
          <p>
            Nuestra MISION es brindar la máxima calidad en el cuidado de pacientes,
            pudiendo satisfacer sus necesidades, trabajando en la prestación de
            servicios destinado al cuidado de la salud en general con sus diferentes
            áreas, centrándonos en buscar el bienestar físico y psíquico de los
            pacientes con la máxima calidad, ética y profesional.
          </p>
          <h3>Visión</h3>
          <p>
            Nuestra VISIÓN es convertirnos en una institución de salud en el interior 
            del país como referente en calidad y profesionalismo con sustento en el tiempo, 
            logrando incorporar tecnología y desarrollo profesional necesario para cubrir 
            las necesidades de nuestros pacientes, brindando siempre la calidad de nuestras 
            prestaciones de servicio y calidez de atención.
          </p>
          <h3>Nuestros Valores</h3>
          <ol>
            <li>Ética profesional.</li>
            <li>Atención personalizada y de calidad.</li>
            <li>Responsabilidad social.</li>
            <li>Idoneidad profesional.</li>
            <li>Respeto por la persona.</li>
            <li>Compromiso con la calidad y nuestro propio desarrollo como institución.</li>
            <li>Eficiencia en el trabajo.</li>
            <li>Crecimiento sustentable.</li>
          </ol>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
/**/
.quienes-somos {
  /* 
  padding: 10rem 1.5rem; 
  background: #2b2f32;
  */
  color: var(--main-text-color);
}
</style>